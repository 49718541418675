<template>
  <div class="animated fadeIn">
    <div class="animated fadeIn">
      <b-card>
        <h4 class="d-block mb-4"><i class="nav-icon icon-plus"></i> Estimates <i class="ml-2 fa fa fa-question-circle" v-tooltip="'<p>1. Select a project</p><p>2. Select a vendor</p><p>3. Create service</p><p>4. Attach AFES</p>'"></i></h4>
        <hr>
        <div class="row my-3">
          <div class="col-md-4">
            <h5>Project</h5>
            <v-select
              class="project-select"
              v-model="project"
              label="projectname"
              :options="projectData"
              :value="projectid"
              placeholder="Search For Project"
              @input="loadVendors()"
            ></v-select>
          </div>
          <div v-if="project" class="col-md-4">
            <h5>Vendor</h5>
            <v-select
              class="project-select"
              v-model="vasid"
              label="company_name"
              :options="vendorData"
            ></v-select>
          </div>
          <div v-if="project" class="col-md-4">
            <h5>&nbsp;</h5>
            <button class="btn btn-success" @click="loadExisting()"><i class="fa fa-eye"></i> View Estimates</button>
          </div>
        </div>
      </b-card>
      <b-card v-if="addNew == 1">
        <h4>Add New Estimate</h4>
        <table class="table table-striped pre-table">
          <thead>
            <tr class="service-rows">
              <th class="center service-name">Service</th>
              <th class="datepickers right">Start Date</th>
              <th class="datepickers right">End Date</th>
              <th class="uom-select">UOM <i class="fa fa-question-circle" v-b-tooltip.hover title="Unit Of Measurement"></i></th>
              <th class="price center">Price/Unit</th>
              <th class="price center">Discounted Price/Unit
              </th>
              <th class="price center">Quantity</th>
              <th class="price center">Discount (%)</th>
              <th class="right">Total</th>
              <th>Add AFE</th>
            </tr>
          </thead>
          <tbody>
            <tr class="service-rows create-service" v-for="(service, i) in preServiceData" style="background: rgba(189, 78, 78, 0.22);">
              <td class="center service-name">
                <h6 class="d-xs-block d-xl-none mobile-header">Service</h6>
                <select :disabled="preServiceData[0].afe.length > 0" class="w-100 rt-select" v-model="service.serviceid" @change="(service.uom = [service.serviceid.pu1, service.serviceid.uom1])">
                  <option v-for="name in filterVendorData" :value="name">
                    {{ name.name }} ({{name.currency}}) {{getVendorName(name.vid)}}
                  </option>
                </select>
              </td>
              <td class="datepickers right">
                <h6 class="d-xs-block d-xl-none">Start Date</h6>
                <datepicker format="MM/dd/yyyy" name="service.start_date" class="rt-date-picker" placeholder="Dropdown" v-validate="'required'" v-model="service.start_date"></datepicker>
              </td>
              <td class="datepickers right">
                <h6 class="d-xs-block d-xl-none">End Date</h6>
                <datepicker class="rt-date-picker" name="service.end_date" placeholder="Dropdown" v-validate="'required'" format="MM/dd/yyyy" v-model="service.end_date"></datepicker>
              </td>
              <td class="left uom-select">
                <h6 class="d-xs-block d-xl-none">Unit Of Measurement</h6>
                <select v-validate="'required'" class="w-100 rt-select" @change = "Pu_toggler(service,service.discount,service.uom[0])"
                 name="service.uom" v-if="service.serviceid" :class="{'is-danger': errors.has('service.uom')}" v-model="service.uom" placeholder="Select">
                  <option selected :value="[service.serviceid.pu1, service.serviceid.uom1]">{{service.serviceid.uom1}}</option>
                  <option :value="[service.serviceid.pu2, service.serviceid.uom2]">{{service.serviceid.uom2}}</option>
                  <option :value="[service.serviceid.pu3, service.serviceid.uom3]">{{service.serviceid.uom3}}</option>
                </select>
              </td>
              <td class="price left v-align-middle">
                <h6 class="d-xs-block d-xl-none" style="margin-bottom: 13px;">Price</h6>
                <span v-if="service.uom">${{formatPrice(service.uom[0])}}</span>
              </td>
              <td class="price left v-align-middle">
                <h6 class="d-xs-block d-xl-none" style="margin-bottom: 13px;">Discounted Price/Unit</h6>
           
<input v-if = "service.serviceid.name"  v-model ="service.discounted_price_per_unit"
   @input="Discount(service,service.discounted_price_per_unit,service.uom[0])">

              </td>

              <td style="padding-bottom: 0.5rem;" class="price left">
                <h6 class="d-xs-block d-xl-none">Quantity</h6>
                <input type="text" class="rt-round" v-model="service.quantity">
              </td>
              <td style="padding-bottom: 0.5rem;" class="price left">
                <h6 class="d-xs-block d-xl-none">Discount (%)</h6>

<input type="text" v-if="service.serviceid.name" class="rt-round" 
v-model="service.discount" @input="discounted(service,service.discount,service.uom[0])">

              </td>
              <td class="right v-align-middle">
                <h6 class="d-xs-block d-xl-none" style="margin-bottom: 13px;">Total</h6>
                <span v-if="service.uom">${{formatPrice((service.quantity * service.uom[0]) - ((service.discount/100) * service.quantity * service.uom[0]))}}</span>
              </td>
              <td class="add-afe-button">
                <button :disabled="beforeEditAfeCacheCatch == 1 || beforeEditCacheCatch == 1" v-if="$moment(service.start_date).format('YYYY-MM-DD') <= $moment(service.end_date).format('YYYY-MM-DD') && service.start_date != '' && service.end_date != '' &&  service.quantity >= 0 && service.uom && service.discount >= 0 && service.discount <= 100" v-b-tooltip.hover title="Add AFE" type="button" v-on:click="addNewPreAfe(service.serviceid.serviceid)" class="view btn rt-d-inline btn-sm btn-success" style="width: 58px; max-width:58px;">AFE <i class="fa fa-plus-circle"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="project && preServiceData.length > 0">
          <table class="table table-striped">
            <thead>
              <tr class="service-rows">
                <th class="actions fit"></th>
                <th class="center">AFE #</th>
                <th class="center fit">% <i class="fa fa-question-circle" v-b-tooltip.hover title="AFE Percentage"></i></th>
                <th class="center">Cost Code #1</th>
                <th class="center">Cost Code #2</th>
                <th class="center">Cost Code #3</th>
                <th class="center fit"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(afe, index) in preServiceData[0].afe">
                <td class="actions fit">
                  <button type="button" v-on:click="cancelAfe(index)" class="btn btn-sm btn-danger mt-1"><i class="fa fa-times-circle"></i></button>
                </td>
                <td class="left">
                  <h6 class="d-xs-block d-xl-none">AFE #</h6>
                  <v-select
                    v-model="afe.afe"
                    label="afenum"
                    :options="afe.chain"
                    :value="afenum"
                    :searchable="false"
                    @input="setcc1(afe.afe.items, index)"
                  ></v-select>
                </td>
                <td class="left fit">
                  <h6 class="d-xs-block d-xl-none">%</h6>
                  <input class="rt-quantity" type="number" step="any" min="0" max="100" v-model="afe.percent">
                </td>
                <td class="left">
                  <h6 class="d-xs-block d-xl-none">Cost Code #1</h6>
                  <v-select
                    v-model="afe.cc1"
                    class="v-select-sm"
                    label="ccone_code"
                    :options="afe.cc1Items"
                    :value="ccone_code"
                    :searchable="false"
                    @input="setcc2(afe.cc1.items, index)"
                  ></v-select>
                </td>
                <td class="left">
                  <h6 class="d-xs-block d-xl-none">Cost Code #2</h6>
                  <v-select
                    v-model="afe.cc2"
                    class="v-select-sm"
                    label="cctwo_code"
                    :options="afe.cc2Items"
                    :value="cctwo_code"
                    :searchable="false"
                    @input="setcc3(afe.cc2.items, index)"
                  ></v-select>
                </td>
                <td class="left">
                  <h6 class="d-xs-block d-xl-none">Cost Code #3</h6>
                  <v-select
                    v-model="afe.cc3"
                    label="ccthree_code"
                    :options="afe.cc3Items"
                    :value="ccthree_code"
                    :searchable="false"
                  ></v-select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="project && preServiceData[0]">
          <button @click="addPreService(preServiceData[0], 0)" v-if="project && $moment(preServiceData[0].start_date).format('YYYY-MM-DD') <= $moment(preServiceData[0].end_date).format('YYYY-MM-DD') && preServiceData[0].start_date != '' && preServiceData[0].end_date != '' &&  preServiceData[0].quantity >= 0 && preServiceData[0].uom && preServiceData[0].discount >= 0 && preServiceData[0].discount <= 100" v-b-tooltip.hover title="Save Service" class="btn btn-sm btn-blue" :disabled="beforeEditAfeCacheCatch == 1 || beforeEditCacheCatch == 1">Save Service To Estimates <i class="fa fa-save"></i></button>
        </div>
      </b-card>
      <div v-if="project && sortedServiceData.length > 0">
        <b-card v-for="(service, index) in sortedServiceData" key="wsid" v-cloak>
          <h5>{{service.service[0].serviceid.name}} ({{getVendorName(service.service[0].serviceid.vid)}})</h5>
          <table class="table table-striped">
            <thead>
              <tr class="service-rows">
                <th class="fit"></th>
                <th class="datepickers right">Start Date</th>
                <th class="datepickers right">End Date</th>
                <th class="uom-select">UOM <i class="fa fa-question-circle" v-b-tooltip.hover title="Unit Of Measurement"></i></th>
                <th class="price center">Price/Unit</th>
                     <th class="price center"> Discounted Price/Unit</th>
                <th class="price center">Quantity</th>
                <th class="price center">Discount (%)</th>
                <th class="right">Total</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr class="service-rows" :class="{editing: service == editedLine}">
                <td class="d-xs-none d-lg-block fit border-0"><button type="button" :disabled="beforeEditCacheCatch == 1 || beforeEditAfeCacheCatch == 1 || service.service.length > 1" v-on:click="removeService(index, service.service[0].wsid)" class="btn btn-sm btn-danger"><i class="fa fa-minus"></i></button></td>
                <!-- <td class="center price v-align-middle position-relative">
                  <h6 class="d-xs-block d-xl-none mobile-header">Service</h6>
                  <div class="view" @click="editData(service, index)">
                    {{service.service[0].serviceid.name}}
                  </div>
                  <div class="edit">
                    <select :disabled="beforeEditCacheCatch == 1 || beforeEditAfeCacheCatch == 1" :class="{'is-danger': errors.has('name')}" class="w-100 rt-select" v-model="service.service[0].serviceid" placeholder="Select" v-validate="'required'" v-if="service.service[0].serviceid" name="service.service[0].serviceid" @change="(service.service[0].uom = [service.service[0].serviceid.pu1, service.service[0].serviceid.uom1])">
                      <option v-for="service in filterVendorData" :value="service">
                        {{ service.name }} ({{service.currency}})
                      </option>
                    </select>
                  </div>
                  <p class="d-xs-block d-xl-none"><b-btn v-b-tooltip.hover title="Open Service Info" style="position: absolute; bottom:10px;right:10px" class="d-xs-block d-lg-none btn-blue btn-sm" v-b-toggle="'service-' + index"><i class="fa fa-chevron-down"></i></b-btn></p>
                  <button type="button" v-b-tooltip.hover title="Remove From Invoice" v-on:click="removeService(index, 9, 1)" style="position: absolute; top:10px;right:10px" class="d-xs-block d-xl-none btn btn-sm btn-danger"><i class="fa fa-minus"></i></button>
                </td> -->
                <td class="datepickers d-none d-lg-table-cell center price v-align-middle">
                  <h6 class="d-xs-block d-xl-none">Start Date</h6>
                  <div class="view" @click="editData(service, index)">
                    {{service.service[0].start_date | moment("MM/DD/YYYY")}}
                  </div>
                  <div class="edit">
                    <datepicker format="MM/dd/yyyy" name="service.service[0].start_date" class="rt-date-picker" placeholder="Dropdown" v-validate="'required'" v-model="service.service[0].start_date"></datepicker>
                  </div>
                </td>
                <td class="datepickers d-none d-lg-table-cell center price v-align-middle">
                  <h6 class="d-xs-block d-xl-none">End Date</h6>
                  <div class="view" @click="editData(service, index)">
                    {{service.service[0].end_date | moment("MM/DD/YYYY")}}
                  </div>
                  <div class="edit">
                    <datepicker v-bind:class="{'date-danger': $moment(service.service[0].start_date).format('YYYY-MM-DD') < $moment(service.service[0].end_date).format('YYYY-MM-DD')}" format="MM/dd/yyyy" name="service.service[0].end_date" class="rt-date-picker" placeholder="Dropdown" v-validate="'required'" v-model="service.service[0].end_date"></datepicker>
                  </div>
                </td>

                <td class="d-none d-lg-table-cell left price v-align-middle">
                  <h6 class="d-xs-block d-xl-none">Unit Of Measurement</h6>
                  <div class="view" v-if="service.service[0].uom" @click="editData(service, index)">
                    {{service.service[0].uom[1]}}
                  </div>
                  <div class="edit">
                    <select v-validate="'required'" class="w-100 rt-select" @change = "Pu_toggler(service.service[0],service.service[0].discount,service.service[0].uom[0])"
                     name="service.service[0].uom" v-if="service.service[0].serviceid" :class="{'is-danger': errors.has('service.service[0].uom')}" v-model="service.service[0].uom" placeholder="Select">
                      <option :value="[service.service[0].serviceid.pu1, service.service[0].serviceid.uom1]">{{service.service[0].serviceid.uom1}}</option>
                      <option :value="[service.service[0].serviceid.pu2, service.service[0].serviceid.uom2]">{{service.service[0].serviceid.uom2}}</option>
                      <option :value="[service.service[0].serviceid.pu3, service.service[0].serviceid.uom3]">{{service.service[0].serviceid.uom3}}</option>
                    </select>
                  </div>
                </td>

                <td class="d-none d-lg-table-cell price left v-align-middle">
                  <h6 class="d-xs-block d-xl-none">Price</h6>
                  <div class="view" @click="editData(service, index)">
                    ${{formatPrice(service.service[0].pu)}}
                  </div>
                  <div class="edit">
                    <span v-if="service.service[0].uom">${{formatPrice(service.service[0].uom[0])}}</span>
                  </div>
                </td>
                    <td class="d-none d-lg-table-cell price left v-align-middle">
                  <h6 class="d-xs-block d-xl-none"> Discounted Price/Unit</h6>
                  <div class="view" @click="editData(service, index)">

        {{service.service[0].discounted_price_per_unit ? formatPrice(service.service[0].discounted_price_per_unit) :   _discount_price_per_unit(service.service[0].discount,service.service[0].uom[0])}}

      </div>
                 
                  <div class="edit">
              <input v-if="service.service[0].serviceid.name && !service.service[0].discounted_price_per_unit" v-bind:value= " _discount_price_per_unit(service.service[0].discount,service.service[0].uom[0])">                       
 <input v-if = " service.service[0].serviceid.name && service.service[0].discounted_price_per_unit"  v-model ="service.service[0].discounted_price_per_unit"  @input="Discount(service.service[0],service.service[0].discounted_price_per_unit,service.service[0].uom[0])">
  
                  </div>
                </td>

                <td class="d-none d-lg-table-cell price left v-align-middle">
                  <h6 class="d-xs-block d-xl-none">Quantity</h6>
                  <div class="view" @click="editData(service, index)">
                    {{service.service[0].quantity}}
                  </div>
                  <div class="edit">
                    <input type="text" class="rt-round" v-model="service.service[0].quantity">
                  </div>
                </td>
                <td class="d-none d-lg-table-cell price left v-align-middle">
                  <h6 class="d-xs-block d-xl-none">Discount</h6>
                  <div class="view" @click="editData(service, index)">
                    ${{formatPrice(service.service[0].discount/100 * service.service[0].pu * service.service[0].quantity)}} ({{service.service[0].discount}}%)
                  </div>
                  <div class="edit">
                   
            <input type="text" v-if="service.service[0].serviceid.name" class="rt-round" 
v-model="service.service[0].discount" @input="discounted(service.service[0], service.service[0].discount,service.service[0].uom[0])">
                  </div>
                </td>
                <td class="d-none d-lg-table-cell right v-align-middle">
                  <h6 class="d-xs-block d-xl-none">Total</h6>
                  <div class="view" @click="editData(service, index)">
                    ${{service.service[0].total}}
                  </div>
                  <div class="edit">
                    <span v-if="service.service[0].uom">${{formatPrice((service.service[0].quantity * service.service[0].uom[0]) - ((service.service[0].discount/100) * service.service[0].quantity * service.service[0].uom[0]))}}</span>
                  </div>
                </td>
                <td class="d-none d-lg-table-cell right">
                  <button v-if="$moment(service.service[0].start_date).format('YYYY-MM-DD') <= $moment(service.service[0].end_date).format('YYYY-MM-DD') && service.service[0].quantity >= 0 && service.service[0].discount >= 0 && service.service[0].discount <= 100" class="edit rt-d-inline mr-2 btn btn-sm btn-primary" type="button" @click="editService(service.service[0])">
                    <i class="fa fa-edit"></i>
                  </button>
                  <b-button v-if="beforeEditAfeCacheCatch != 1 && beforeEditCacheCatch != 1" class="btn-sm" style="width: 100%; max-width: 62px;" v-b-toggle="'c-' + index" variant="primary">AFE'S <i class="fa fa-chevron-down"></i></b-button>
                </td>
              </tr>
            </tbody>
          </table>
          <b-collapse accordion="afe-collapse" v-bind:id="'c-' + index" class="mt-2">
            <table class="table table-striped">
            <thead>
              <tr class="service-rows">
                <th class="actions fit"></th>
                <th class="center">AFE #</th>
                <th class="center fit">% <i class="fa fa-question-circle" v-b-tooltip.hover title="AFE Percentage"></i></th>
                <th class="center">Cost Code #1</th>
                <th class="center">Cost Code #2</th>
                <th class="center">Cost Code #3</th>
                <th class="center fit"></th>
              </tr>
            </thead>
            <tbody>
              <tr class="v-align afe-table" :class="{editingAfe: afe == editedAfeLine}" v-for="(afe, aIndex) in service.service" v-if="afe.afenum">
                <td class="actions" v-b-tooltip.hover title="Remove AFE From Invoice"><button v-if="!afe.add" :disabled="beforeEditAfeCacheCatch == 1 || beforeEditCacheCatch == 1" type="button" v-on:click="removeAfe(afe.apwid)" class="fit btn btn-sm btn-danger"><i class="fa fa-minus-circle"></i></button><button v-if="afe.add == 1" type="button" v-on:click="cancelAfe()" class="btn btn-sm btn-danger"><i class="fa fa-times-circle"></i></button></td>
                <td class="left">
                  <h6 class="d-xs-block d-xl-none">AFE #</h6>
                  <div v-if="!afe.add" class="view" @click="editAfeData(afe, index, aIndex)">
                    {{afe.afenum}}
                  </div>
                  <div v-if="!afe.add" class="edit">
                    <v-select
                      v-model="edit_afe"
                      label="afenum"
                      :options="serviceChainData"
                      :value="afenum"
                      @input="getcc1()"
                    ></v-select>
                  </div>
                  <div v-if="afe.add == 1">
                    <v-select
                      v-model="add_afe"
                      label="afenum"
                      :options="serviceChainData"
                      :value="afenum"
                      :searchable="false"
                      @input="addcc1()"
                    ></v-select>
                  </div>
                </td>
                <td class="left fit">
                  <h6 class="d-xs-block d-xl-none">%</h6>
                  <div v-if="!afe.add" class="view" @click="editAfeData(afe, index, aIndex)">
                    {{afe.percentage}}
                  </div>
                  <div v-if="!afe.add" class="edit">
                    <input class="rt-quantity" type="number" step="any" min="0" max="100" v-model="edit_percentage">
                  </div>
                  <div v-if="afe.add == 1">
                    <input class="rt-quantity" type="number" step="any" min="0" max="100" v-model="add_percentage">
                  </div>
                </td>
                <td class="left">
                  <h6 class="d-xs-block d-xl-none">Cost Code #1</h6>
                  <div v-if="!afe.add" class="view" @click="editAfeData(afe, index, aIndex)">
                    {{afe.ccone_code}}
                  </div>
                  <div v-if="!afe.add" class="edit">
                    <v-select
                      v-model="edit_ccone_code"
                      class="v-select-sm"
                      label="ccone_code"
                      :options="cc1Data"
                      :value="ccone_code"
                      @input="getcc2()"
                    ></v-select>
                  </div>
                  <div v-if="afe.add == 1">
                    <v-select
                      v-model="add_ccone_code"
                      class="v-select-sm"
                      label="ccone_code"
                      :options="cc1Data"
                      :value="ccone_code"
                      :searchable="false"
                      @input="addcc2()"
                    ></v-select>
                  </div>
                </td>
                <td class="left">
                  <h6 class="d-xs-block d-xl-none">Cost Code #2</h6>
                  <div v-if="!afe.add" class="view" @click="editAfeData(afe, index, aIndex)">
                    {{afe.cctwo_code}}
                  </div>
                  <div v-if="!afe.add" class="edit">
                    <v-select
                      v-model="edit_cctwo_code"
                      class="v-select-sm"
                      label="cctwo_code"
                      :options="cc2Data"
                      :value="cctwo_code"
                      @input="getcc3()"
                    ></v-select>
                  </div>
                  <div v-if="afe.add == 1">
                    <v-select
                      v-model="add_cctwo_code"
                      class="v-select-sm"
                      label="cctwo_code"
                      :options="cc2Data"
                      :value="cctwo_code"
                      :searchable="false"
                      @input="addcc3()"
                    ></v-select>
                  </div>
                </td>
                <td class="left">
                  <h6 class="d-xs-block d-xl-none">Cost Code #3</h6>
                  <div v-if="!afe.add" class="view" @click="editAfeData(afe, index, aIndex)">
                    <div v-if="afe.ccthree_code"><span v-if="!afe.ccthree_code.includes('---')">
                      {{afe.ccthree_code}}
                      </span>
                    </div>
                  </div>
                  <div v-if="!afe.add" class="edit">
                    <v-select
                      v-model="edit_ccthree_code"
                      label="ccthree_code"
                      :options="cc3Data"
                      :value="ccthree_code"
                    ></v-select>
                  </div>
                  <div v-if="afe.add == 1">
                    <v-select
                      v-model="add_ccthree_code"
                      label="ccthree_code"
                      :options="cc3Data"
                      :value="ccthree_code"
                      :searchable="false"
                    ></v-select>
                  </div>
                </td>
                <td class="actions add-afe-button" style="width: 5%;">
                  <div v-if="!afe.add" class="edit">
                    <button type="button" v-on:click="editAddAfeToInvoice(afe.apwid, afe.wsid, afe)" class="btn btn-sm btn-primary"><i class="fa fa-edit"></i></button>
                  </div>
                  <div v-if="afe.add == 1">
                    <button type="button" v-b-tooltip.hover title="Add AFE" v-on:click="addAfeToInvoice(afe.service_ccid, afe.wsid, afe.id, afe)" class="btn btn-sm btn-success"><i class="fa fa-plus-circle"></i></button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <button v-b-tooltip.hover title="Add AFE" :disabled="beforeEditAfeCacheCatch == 1 || beforeEditCacheCatch == 1" type="button" v-on:click="addNewAfe(service.wsid, service.service[0].serviceid.serviceid, index, service)" class="view btn rt-d-inline btn-sm btn-success">Add AFE <i class="fa fa-plus-circle"></i></button>
          </b-collapse>
        </b-card>
      </div>
    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker'
import { Validator } from 'vee-validate';
import moment from 'moment';

export default {
  name: 'WSSEstimates',
  components: {
    vSelect,
    Datepicker,
  },
  watch: {
    fullVendorServices: function (val) {
      if(val.length > 0 && val.length < 2){
        this.extendLine();
      }
    }
  },
  data () {
    return {
      project: [],
      serviceData: [],
      vendorServices: [],
      editedAfeLine: [],
      beforeEditCacheCatch: 0,
      beforeEditAfeCacheCatch: 0,
      preServiceData: [],
      projectid: 0,
      vasid: 0,
      vendorData: [],
      projectData: [],
      vendorServices: [],
      fullVendorServices: [],
      editedLine: [],
      beforeEditCache: [],
      addAfeButtons: 1,
      afe: '',
      serviceChainData: [],
      afenum: '',
      cc1: '',
      cc1Data: [],
      ccone_code: '',
      cc2: '',
      cctwo_code: '',
      cc2Data: [],
      cctwo_code: '',
      cc3: '',
      ccthree_code: '',
      cc3Data: [],
      ccthree_code: '',
      addAfeShow: false,
      percent: 100,
      afes: [],
      edit_afe: [],
      edit_percentage: 0,
      edit_ccone_code: [],
      edit_cctwo_code: [],
      edit_ccthree_code: [],
      add_afe: [],
      add_percentage: 100,
      add_ccone_code: [],
      add_cctwo_code: [],
      add_ccthree_code: [],
      editAfe: 0,
      addNew: 0,
      discounted_price_per_unit:0,
    }
  },
  methods: {

    //price per unit to discounted price per unit toggler;

 Pu_toggler(service,discount,pu){
 
if(!discount){

return service.discounted_price_per_unit = pu.toFixed(2);

}else{

 return service.discounted_price_per_unit = pu*(1-discount/100); 

}

},


Discount(service,discountedPrice,pu){


  service.discount = 100*(1-(discountedPrice/pu));  // pu- price per unit

  return service.discount;

  
},

discounted(service,discount,pu){

return service.discounted_price_per_unit = pu*(1-discount/100); // pu- price per unit


},

    getVendorName(id){
      var temp = this.vendorData.filter(x=>x.vid === id)
      var name = temp[0].company_name
      return name
    },
    async editAddAfeToInvoice(id, wsid, afe){
      if(this.edit_percentage > 100 || this.edit_percentage <= 0){
        this.$toasted.show('AFE Percentage Error', {type: 'error', duration: '3000'})
        return
      }

      var cc_num = [];
      if(this.edit_ccthree_code !== undefined && this.edit_ccthree_code !== null){
        cc_num = this.edit_ccthree_code.items[0];
      }
      else {
        cc_num = this.edit_cctwo_code.items[0];
      }

      if(this.serviceData.filter(e => e.service_ccid === cc_num.service_ccid && e.wsid === wsid).length > 0){
        if(cc_num.service_ccid == this.beforeEditAfeCache.service_ccid){
        }
        else{
          console.log('test')
          this.$toasted.show('AFE Duplicate', {type: 'error', duration: '3000'})
          return;
        }
      }

      let data = JSON.stringify({
        id: afe.apwid,
        projectid: this.project.projectid,
      })


      const firstRequest = await this.$axios.post('/wss/delete/wafp', data)

      let data2 = JSON.stringify({
        id: wsid,
        projectid: this.project.projectid,
        service_ccid: cc_num.service_ccid,
        percentage: this.edit_percentage,
      })

      const secondRequest = await this.$axios.post('/wss/create/wafp', data2)
      .then(response => {
        var find = this.serviceData.findIndex(x=>x.apwid == id)
        var tempService = this.fullVendorServices.filter(x=>x.serviceid === afe.id);

        Vue.set(this.serviceData, find, {
          serviceid: tempService[0],
          wsid: wsid,
          apwid: response.data.apwid,
          ccthree_code: cc_num.ccthree_code,
          cctwo_code: cc_num.cctwo_code,
          ccone_code: cc_num.ccone_code,
          afenum: cc_num.afenum,
          percentage: this.edit_percentage,
          id: afe.id,
        })

        this.addAfeButtons = 1;
        this.editedAfeLine = [];
        this.beforeEditAfeCacheCatch = 0;
      })
      .catch(error => {
        this.$toasted.show('Error', {type: 'error', duration: '3000'})
      })
    },
    editAfeData (line, index, AIndex) {
      if(this.beforeEditAfeCacheCatch == 1){
        this.$toasted.show('Please save edit first', {type: 'error', duration: '3000'})
        return
      }
      this.loadEditingAfe(line, index, AIndex)

      this.beforeEditAfeCache = Object.assign({}, line);
      this.editedAfeLine = line
      this.beforeEditAfeCacheCatch = JSON.parse(JSON.stringify(1));
    },
    cancelAfe(index){
      var find = this.serviceData.findIndex(x=>x.add == 1)
      Vue.delete(this.serviceData, find)
      this.beforeEditAfeCacheCatch = 0;
      this.beforeEditCacheCatch = 0;
    },
    addNewPreAfe(id){
      this.$http.get("/wss/get/service_chains/" + this.project.companyid + "/" + this.project.projectid + "/" + id)
      .then(response => {
        if(response.data.result.length > 0){
          var result = this.lodash.groupBy(response.data.result,'afenum')
          var serviceChainData = Object.keys(result).map(key => ({ afenum: key, items: result[key] }));
          var afe = serviceChainData[0];

          //cc1
          var temp = afe.items;
          temp = this.lodash.groupBy(temp, 'ccone_code');
          var cc1Data = Object.keys(temp).map(key => ({ ccone_code: key, items: temp[key] }));
          var cc1 = cc1Data[0];

          //cc2
          var temp2 = cc1.items;
          temp2 = this.lodash.groupBy(cc1.items, 'cctwo_code');
          var cc2Data = Object.keys(temp2).map(key => ({ cctwo_code: key, items: temp2[key] }));
          var cc2 = cc2Data[0];
          //cc3

          var temp3 = cc2.items;
          temp3 = this.lodash.groupBy(temp3, 'ccthree_code');
          var cc3Data = Object.keys(temp3).map(key => ({ ccthree_code: key, items: temp3[key] }));
          var cc3 = cc3Data[0];

          this.preServiceData[0].afe.push({
            chain: serviceChainData,
            afe: serviceChainData[0],
            percent: 100,
            cc1: cc1Data[0],
            cc1Items: cc1Data[0].items,
            cc2: cc2Data[0],
            cc2Items: cc2Data[0].items,
            cc3: cc3Data[0],
            cc3Items: cc3Data[0].items,
          });
        }
        if(response.data.result.length === 0){
          this.$toasted.show('No AFE Numbers Found.', {type: 'error', duration: '3000'})
        }
      })
      .catch(error => {
      })
    },
    addPreService(service, i) {
      if(service.serviceid && service.uom){
        let data = JSON.stringify({
          vasid: service.serviceid.vasid,
          serviceid: service.serviceid.serviceid,
          start_date:  this.$moment(service.start_date).format('YYYY-MM-DD'),
          end_date:  this.$moment(service.end_date).format('YYYY-MM-DD'),
          discounted_price_per_unit:service.discounted_price_per_unit,
          discount:  service.discount,
          quantity:  service.quantity,
          projectid:  this.project.projectid,
          uom:  service.uom[1],
          pu: service.uom[0],
          currency:  service.serviceid.currency,
        })

        this.$axios.post('/wss/create/service', data)
        .then(response => {
          var total = this.formatPrice((service.quantity * service.uom[0]) - ((service.discount/100) * service.quantity * service.uom[0]))
          var uom = [service.uom[0],service.uom[1]];
          var number = 0;
          if(this.serviceData.length != 0){
            number = this.serviceData[this.serviceData.length-1].number + 1;
          }

          this.serviceData.push({
            wsid: response.data.wsid,
            vsid: service.vsid,
            id: service.serviceid.serviceid,
            serviceid: service.serviceid,
            start_date:  this.$moment(service.start_date).toDate(),
            end_date:  this.$moment(service.end_date).toDate(),
            discounted_price_per_unit:service.discounted_price_per_unit,
            discount:  service.discount,
            quantity:  service.quantity,
            uom:  uom,
            pu: service.uom[0],
            total: total,
          })
          this.createWafes(this.preServiceData[0].afe, response.data.wsid, this.preServiceData[0].serviceid)
          Vue.delete(this.preServiceData, i);

          if(this.preServiceData.length === 0){
            this.extendLine()
          }
        })
        .catch(error => {
        })
      }
    },
    loadEditingAfe(line, index, AIndex){
      this.editAfe = 1;

      this.$http.get("/wss/get/service_chains/" + this.project.companyid + "/" + this.project.projectid + "/" + line.id)
      .then(response => {
        //Group AFES

        var result = this.lodash.groupBy(response.data.result,'afenum')
        this.serviceChainData = Object.keys(result).map(key => ({ afenum: key, items: result[key] }));
        var currentAfe = this.serviceChainData.findIndex(x=>x.afenum == line.afenum)
        this.edit_afe = this.serviceChainData[currentAfe]


        this.edit_percentage = line.percentage;
        this.editAfe = 1;

        //cc1
        var temp = this.edit_afe.items;
        temp = this.lodash.groupBy(temp, 'ccone_code');
        this.cc1Data = Object.keys(temp).map(key => ({ ccone_code: key, items: temp[key] }));
        var cc1Index = this.cc1Data.findIndex(x=>x.ccone_code == line.ccone_code)
        this.edit_ccone_code = this.cc1Data[cc1Index];
        this.editAfe = 1;


        //cc2
        var temp2 = this.edit_ccone_code.items;
        temp2 = this.lodash.groupBy(temp2, 'cctwo_code');
        this.cc2Data = Object.keys(temp2).map(key => ({ cctwo_code: key, items: temp2[key] }));
        var cc2Index = this.cc2Data.findIndex(x=>x.cctwo_code == line.cctwo_code)
        this.edit_cctwo_code = this.cc2Data[cc2Index];
        this.editAfe = 1;

        //cc3
        var temp3 = this.edit_cctwo_code.items.filter(o => !o.ccthree_code.includes('---'));;
        temp3 = this.lodash.groupBy(temp3, 'ccthree_code');
        this.cc3Data = Object.keys(temp3).map(key => ({ ccthree_code: key, items: temp3[key] }));
        var cc3Index = this.cc3Data.findIndex(x=>x.ccthree_code == line.ccthree_code)
        this.edit_ccthree_code = this.cc3Data[cc3Index];
        this.editAfe = 1;

      }).then(response => {
        this.beforeEditAfeCacheCatch = JSON.parse(JSON.stringify(1));
        this.editAfe = 0;
      })
      .catch(error => {
      })
    },
    editData (line, index) {
      if(this.beforeEditCacheCatch == 1 || this.beforeEditAfeCacheCatch == 1){
        this.$toasted.show('Please save edit first', {type: 'error', duration: '3000'})
        return
      }

      this.beforeEditCache = Object.assign({}, line);
      this.editedLine = line
      this.beforeEditCacheCatch = JSON.parse(JSON.stringify(1));
    },
    createWafes(afes, wsid, serviceid){
      for (var i = 0; i < afes.length; i++) {
        if(afes[i].percent > 100 || afes[i].percent <= 0){
          this.$toasted.show('AFE Percentage Error', {type: 'error', duration: '3000'})
          return
        }
        var cc_num = [];
        if(afes[i].cc3 !== undefined && afes[i].cc3 !== null){
          cc_num = afes[i].cc3.items[0];
        }
        else {
          cc_num = afes[i].cc2.items[0];
        }
        var percent = afes[i].percent;

        let data = JSON.stringify({
          projectid: this.project.projectid,
          id: wsid,
          service_ccid: cc_num.service_ccid,
          percentage: percent
        })
        this.$axios.post('/wss/create/wafp', data)
        .then(response => {
          this.serviceData.push({
            wsid: wsid,
            apwid: response.data.apwid,
            ccthree_code: cc_num.ccthree_code,
            cctwo_code: cc_num.cctwo_code,
            ccone_code: cc_num.ccone_code,
            afenum: cc_num.afenum,
            service_ccid: cc_num.service_ccid,
            percentage: percent,
            id: serviceid.serviceid
          })
        })
        .catch(error => {
        })
      }
    },
    editService(service) {
      if(service.serviceid && service.uom){
        this.loading = true;

        let data = JSON.stringify({
          wsid: service.wsid,
          vasid: service.serviceid.vasid,
          serviceid: service.serviceid.serviceid,
          start_date:  this.$moment(service.start_date).format('YYYY-MM-DD'),
          end_date:  this.$moment(service.end_date).format('YYYY-MM-DD'),
          discounted_price_per_unit:service.discounted_price_per_unit,
          discount:  service.discount,
          projectid: this.project.projectid,
          quantity:  service.quantity,
          uom:  service.uom[1],
          pu: service.uom[0],
          currency:  service.serviceid.currency,
        })

        this.$axios.post('/wss/edit/service', data)
        .then(response => {
          var total = this.formatPrice((service.quantity * service.uom[0]) - ((service.discount/100) * service.quantity * service.uom[0]))
          var uom = [service.uom[0],service.uom[1]];

          var i = this.serviceData.findIndex(x=>x.wsid == service.wsid)

          Vue.set(this.serviceData, i, {
            wsid: service.wsid,
            vsid: response.data.vsid,
            id: service.serviceid.serviceid,
            serviceid: service.serviceid,
            start_date:  this.$moment(service.start_date).toDate(),
            end_date: this.$moment(service.end_date).toDate(),
            discounted_price_per_unit:service.discounted_price_per_unit,
            discount:  service.discount,
            percentage:  0,
            quantity:  service.quantity,
            uom:  uom,
            pu: service.uom[0],
            total: total,
          });

          this.editedLine = [];
          this.beforeEditCacheCatch = 0;
          this.loading = false;
        })
        .catch(error => {
        })
      }
    },
    addNewAfe (id, serviceid, index, all) {
      this.$http.get("/wss/get/service_chains/" + this.project.companyid + "/" + this.project.projectid + "/" + serviceid)
      .then(response => {

        var result = this.lodash.groupBy(response.data.result,'afenum')
        this.serviceChainData = Object.keys(result).map(key => ({ afenum: key, items: result[key] }));
        this.add_afe = this.serviceChainData[0]
        this.add_percentage = 100;

        //cc1
        var temp = this.add_afe.items;
        temp = this.lodash.groupBy(temp, 'ccone_code');
        this.cc1Data = Object.keys(temp).map(key => ({ ccone_code: key, items: temp[key] }));
        this.add_ccone_code = this.cc1Data[0];

        //cc2
        var temp2 = this.add_ccone_code.items;
        temp2 = this.lodash.groupBy(temp2, 'cctwo_code');
        this.cc2Data = Object.keys(temp2).map(key => ({ cctwo_code: key, items: temp2[key] }));
        this.add_cctwo_code = this.cc2Data[0];

        //cc3
        var temp3 = this.add_cctwo_code.items;
        temp3 = this.lodash.groupBy(temp3, 'ccthree_code');
        this.cc3Data = Object.keys(temp3).map(key => ({ ccthree_code: key, items: temp3[key] }));
        this.add_ccthree_code = this.cc3Data[0];

        this.serviceData.push({
          wsid: id,
          add: 1,
          id:  serviceid,
          afenum: ' '
        })
      }).then(response => {
        this.beforeEditAfeCacheCatch = JSON.parse(JSON.stringify(1));
        this.editAfe = 0;
      })
      .catch(error => {
      })
    },
    addAfeToInvoice(id, wsid, serviceid, all){
      if(this.add_percentage > 100 || this.add_percentage <= 0){
        this.$toasted.show('AFE Percentage Error', {type: 'error', duration: '3000'})
        return
      }

      var cc_num = [];
      if(this.add_ccthree_code !== undefined && this.add_ccthree_code !== null){
        cc_num = this.add_ccthree_code.items[0];
      }
      else {
        cc_num = this.add_cctwo_code.items[0];
      }

      if(this.serviceData.filter(e => e.service_ccid === cc_num.service_ccid && e.wsid === wsid).length > 0){
        this.$toasted.show('AFE Duplicate', {type: 'error', duration: '3000'})
        return;
      }

      let data = JSON.stringify({
        projectid: this.project.projectid,
        id: wsid,
        service_ccid: cc_num.service_ccid,
        percentage: this.add_percentage
      })

      this.$axios.post('/wss/create/wafp', data)
      .then(response => {
        var find = this.serviceData.findIndex(x=>x.add == 1)

        Vue.set(this.serviceData, find, {
          wsid: wsid,
          apwid: response.data.apwid,
          ccthree_code: cc_num.ccthree_code,
          cctwo_code: cc_num.cctwo_code,
          ccone_code: cc_num.ccone_code,
          afenum: cc_num.afenum,
          service_ccid: cc_num.service_ccid,
          percentage: this.add_percentage,
          id: serviceid
        })
        this.beforeEditAfeCacheCatch = 0;
      })
      .catch(error => {
      })
    },
    setcc1(afe, index) {
      var temp = this.lodash.groupBy(afe, 'ccone_code');
      var cc = Object.keys(temp).map(key => ({ ccone_code: key, items: temp[key] }));

      this.preServiceData[0].afe[index].cc1 = cc[0]
      this.preServiceData[0].afe[index].cc1Items = cc
    },
    setcc2(cc, index) {
      var temp = this.lodash.groupBy(cc, 'cctwo_code');
      var cc = Object.keys(temp).map(key => ({ cctwo_code: key, items: temp[key] }));

      this.preServiceData[0].afe[index].cc2 = cc[0]
      this.preServiceData[0].afe[index].cc2Items = cc
    },
    setcc3(cc, index) {
      var filterOut = cc.filter(o => !o.ccthree_code.includes('---'));

      var temp = this.lodash.groupBy(filterOut, 'ccthree_code');
      var cc = Object.keys(temp).map(key => ({ ccthree_code: key, items: temp[key] }));

      this.preServiceData[0].afe[index].cc3 = cc[0]
      this.preServiceData[0].afe[index].cc3Items = cc
    },
    getcc1() {
      if(this.editAfe == 0){
        var temp = this.edit_afe.items;
        temp = this.lodash.groupBy(temp, 'ccone_code');
        this.cc1Data = Object.keys(temp).map(key => ({ ccone_code: key, items: temp[key] }));
        this.edit_ccone_code = this.cc1Data[0];
      }
    },
    getcc2() {
      if(this.editAfe == 0){
        if(this.edit_ccone_code){
          if(this.edit_ccone_code.items){
            var temp2 = this.edit_ccone_code.items;
            temp2 = this.lodash.groupBy(temp2, 'cctwo_code');
            this.cc2Data = Object.keys(temp2).map(key => ({ cctwo_code: key, items: temp2[key] }));
            this.edit_cctwo_code = this.cc2Data[0];
          }
        }
      }
    },
    getcc3() {
      if(this.editAfe == 0){
        if(this.edit_cctwo_code){
          if(this.edit_cctwo_code.items){
            var temp3 = this.edit_cctwo_code.items.filter(o => !o.ccthree_code.includes('---'));
            temp3 = this.lodash.groupBy(temp3, 'ccthree_code');
            this.cc3Data = Object.keys(temp3).map(key => ({ ccthree_code: key, items: temp3[key] }));
            this.edit_ccthree_code = this.cc3Data[0];
          }
        }
      }
    },
    addcc1() {
      var temp = this.add_afe.items;
      temp = this.lodash.groupBy(temp, 'ccone_code');
      this.cc1Data = Object.keys(temp).map(key => ({ ccone_code: key, items: temp[key] }));
      this.add_ccone_code = this.cc1Data[0];
    },
    addcc2() {
      if(this.add_ccone_code){
        if(this.add_ccone_code.items){
          var temp2 = this.add_ccone_code.items;
          temp2 = this.lodash.groupBy(temp2, 'cctwo_code');
          this.cc2Data = Object.keys(temp2).map(key => ({ cctwo_code: key, items: temp2[key] }));
          this.add_cctwo_code = this.cc2Data[0];
        }
      }
    },
    addcc3() {
      if(this.add_cctwo_code){
        if(this.add_cctwo_code.items){
          var temp3 = this.add_cctwo_code.items.filter(o => !o.ccthree_code.includes('---'));
          temp3 = this.lodash.groupBy(temp3, 'ccthree_code');
          this.cc3Data = Object.keys(temp3).map(key => ({ ccthree_code: key, items: temp3[key] }));
          this.add_ccthree_code = this.cc3Data[0];
        }
      }
    },
    removeService (i, wsid) {
      let data = JSON.stringify({
        id: wsid,
        projectid: this.project.projectid,
      })
      this.$axios.post('/wss/delete/service', data)
      .then(response => {
        Vue.delete(this.serviceData, i);
      })
      .catch(error => {
      })
    },
    removeAfe (apwid) {
      var i = this.serviceData.findIndex(x=>x.apwid == apwid)

      let data = JSON.stringify({
        id: apwid,
        projectid: this.project.projectid,
      })
      console.log(data)
      this.$axios.post('/wss/delete/wafp', data)
      .then(response => {
        Vue.delete(this.serviceData, i);
      })
      .catch(error => {
      })
    },
    extendLine(){
      this.preServiceData.push(Vue.util.extend({}, {
        start_date: new Date,
        end_date: new Date,
        serviceid: [],
        quantity: 0,
        discount:  0,
        percentage:  0,
        quantity:  0,
        total: 0,
        afe: [],
      }))
    },
    loadData() {
      this.$http.get('/wss/get/projects')
      .then(response => {
        this.projectData = response.data.result;
      })
    },
    loadVendors() {
      if(this.project.projectid){
        this.$http.get('/wss/get/vendor_services/' + this.project.projectid)
        .then(response => {
          this.vendorData = this.lodash.uniqBy(response.data.result, 'company_name');

          this.vendorServices = response.data.result;

          for (var i = 0; i < this.vendorServices.length; i++) {
            var id = this.vendorServices[i].vasid;
            var name = this.vendorServices[i].name;
            this.createFullList(id, name, i)
          }
        })
      }
    },
    createFullList(id, name, i){
      this.$http.get('/wss/get/service_details/' + this.project.projectid + '/' + id)
      .then(response => {
        var temp = response.data.result;

        temp.name = this.vendorServices[i].name;
        temp.vasid = id;
        temp.vid = this.vendorServices[i].vid;
        this.fullVendorServices.push(temp);
      })
    },
    loadExisting(){
      this.addNew = 1;
      this.$session.set('e-vendor', this.vasid)
      this.$session.set('e-project', this.project)

      if(this.project.projectid){
        this.serviceData = [];

        this.$http.get('/wss/get/full_services/' + this.project.projectid)
        .then(response => {
          var temp = response.data.result;

          for (var i = 0; i < temp.length; i++) {
            var tempService = this.fullVendorServices.filter(x=>x.serviceid === temp[i].serviceid);

            this.serviceData.push({
              wsid: temp[i].wsid,
              vsid: temp[i].vsid,
              id: temp[i].serviceid,
              serviceid: tempService[0],
              start_date: this.$moment(temp[i].start_date).toDate(),
              end_date: this.$moment(temp[i].end_date).toDate(),
              discount: temp[i].discount,
              quantity: temp[i].quantity,
              uom: [
                temp[i].pu,
                temp[i].uom
              ],
              pu: temp[i].pu,
              total: this.formatPrice((temp[i].pu * temp[i].quantity) - (temp[i].discount/100 * (temp[i].pu * temp[i].quantity))),
              apwid: temp[i].apwid,
              service_ccid: temp[i].service_ccid,
              ccthree_code: temp[i].ccthree_code,
              cctwo_code: temp[i].cctwo_code,
              ccone_code: temp[i].ccone_code,
              afenum: temp[i].afenum,
              percentage: temp[i].percentage,
            })
          }
        })
        this.$http.get('/wss/get/services/' + this.project.projectid)
        .then(response => {
          var temp = response.data.result;

          for (var i = 0; i < temp.length; i++) {
            var tempService = this.fullVendorServices.filter(x=>x.serviceid === temp[i].serviceid);
            this.serviceData.push({
              wsid: temp[i].wsid,
              vsid: temp[i].vsid,
              id: temp[i].serviceid,
              serviceid: tempService[0],
              start_date: this.$moment(temp[i].start_date).toDate(),
              end_date: this.$moment(temp[i].end_date).toDate(),
              discount: temp[i].discount,
              quantity: temp[i].quantity,
              uom: [
                temp[i].pu,
                temp[i].uom
              ],
              pu: temp[i].pu,
              total: this.formatPrice((temp[i].pu * temp[i].quantity) - (temp[i].discount/100 * (temp[i].pu * temp[i].quantity))),
            })
          }
        })
      }
    },
  },
  computed: {
    sortedServiceData: function () {
      return this.lodash.chain(this.serviceData)
      .groupBy('wsid')
      .map((service, wsid) => ({ service, wsid }))
      .reverse()
      .value();
    },
    filterVendorData: function () {
      if(this.vasid){
        return this.fullVendorServices.filter(x=>x.vid === this.vasid.vid)
      }
      return this.fullVendorServices
    },
  },
  mounted(){
    if(this.$session.get('e-project') && this.$session.get('e-vendor')){
      this.project = this.$session.get('e-project');
      this.vasid = this.$session.get('e-vendor');
    }
    this.loadData();
  }
}

</script>
